import { styled, Spinner } from '@lhaley2011/react-library';

const LoadingSpinner = styled(Spinner)`
  display: flex;
  height: 75%;
`;
const LoadingMessage = styled.span``;

const Loading = ({
  message,
  className,
}: {
  message?: string;
  className?: string;
}): JSX.Element => {
  return (
    <>
      {message && <LoadingMessage>{message}</LoadingMessage>}
      <LoadingSpinner className={className} />;
    </>
  );
};

export default Loading;
