export interface LoginFragmentResponse {
  id: string;
  authenticated: boolean;
  expires: string;
  features: { name: string; value: string }[];
  user: {
    id: string;
    name: string;
    email: string;
  };
  account: {
    id: string;
    name: string;
  };
  accounts: {
    id: string;
    name: string;
  }[];
  forms: {
    id: string;
    label: string;
  }[];
  role: string;
  rights: {
    name: string;
    resource: string;
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
  }[];
}

export default (type = 'LoginResponse'): string => `
  fragment loginResponse on ${type} {
    id
    authenticated
    expires
    role
    rights {
      name
      resource
      create
      read
      update
      delete
    }
    features {
      name
      value
    }
    user {
      id
      name
      email
    }
    account {
      id
      name
    }
    accounts {
      id
      name
    }
    forms {
      id
      label
    }
  }
`;
