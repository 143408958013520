/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useToast,
  ThemeName,
  useTheme,
  useSuspendedPromise,
  ErrorBoundary,
} from '@lhaley2011/react-library';
import getLoggedInUser from '../queries/getLoggedInUser';
import { NavType, useNavigation } from './NavigationContext';

const defaultTitle = 'Boilerplate';

export interface Form {
  id: string;
  label: string;
}

export interface User {
  id: string;
  email: string;
  name: string;
}
export interface Account {
  id: string;
  name: string;
}
export interface Right {
  name: string;
  resource: string;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export interface Features {
  [key: string]: string;
}

interface AppProviderProps {
  children: React.ReactNode;
}

interface Data {
  authenticated: boolean;
  memberships: Account[];
  user: User;
  account: Account;
  features: Features;
  forms: Form[];
  rights: Right[];
}

export interface IAuthContext {
  user?: User;
  account?: Account;
  rights: Right[];
  forms: Form[];
  features: Features;
  memberships: Account[];
  signout: () => void;
  signin: (loginData: Data) => void;
  loggedIn: boolean;
  processError: (
    error: Error | string,
    to?: string,
    skipToast?: boolean,
  ) => void;
  reinit: () => void;
}

const authAPI = useSuspendedPromise(getLoggedInUser);

// createContext matches the shape that the consumers expect!
export const AuthContext = createContext<IAuthContext>({
  user: undefined,
  account: undefined,
  rights: [],
  features: {},
  forms: [],
  memberships: [],
  signout: () => {},
  signin: () => {},
  loggedIn: false,
  processError: () => {},
  reinit: () => {},
});

export const AuthProvider = ({ children }: AppProviderProps): JSX.Element => {
  const [data, setData] = useState<Data | undefined>(undefined);
  const navigate = useNavigate();
  const { setTheme, reset: resetTheme } = useTheme();
  const { setNavType, setDebug, reset: resetNavigation } = useNavigation();
  const toast = useToast();

  const signin = (loginData: Data) => {
    setData(loginData);
    document.title = loginData?.account?.name || defaultTitle;
    setTheme(loginData?.features?.theme as ThemeName);
    setNavType(loginData?.features?.navType as NavType);
    setDebug(loginData?.features?.debug === 'true');
  };
  const signout = () => {
    setData(undefined);
    resetTheme();
    resetNavigation();
    document.title = defaultTitle;
  };

  const processError = (
    err: Error | string,
    to?: string,
    skipToast?: boolean,
  ) => {
    const message = typeof err === 'string' ? err : err.message;
    const code =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      typeof err === 'string' ? undefined : (err as any)?.extensions?.code;
    if (code === 'UNAUTHENTICATED' || message === 'Unauthorized Error') {
      const username = data?.user?.email;
      signout();
      navigate(`/login?redirect=${window.location.pathname}`, {
        replace: true,
        state: { referrer: window.location.pathname, username },
      });
      return;
    }
    if (skipToast !== true) {
      toast.error(message);
    }
    // eslint-disable-next-line no-console
    console.debug(err);
    if (to) {
      navigate(to, {
        replace: true,
        state: { referrer: window.location.pathname },
      });
    }
  };

  if (data?.account === undefined || data?.user === undefined) {
    const login = authAPI.read();
    if (login.data !== null && login.data !== undefined) {
      signin(login.data);
    }
  }

  const reinit = () => {
    authAPI.rerun();
  };

  const value = useMemo(
    () => ({
      user: data?.user,
      account: data?.account,
      rights: data?.rights || [],
      features: data?.features || {},
      forms: data?.forms || [],
      memberships: data?.memberships || [],
      signout,
      signin,
      loggedIn: data?.authenticated === true,
      processError,
      reinit,
    }),
    [data],
  );
  return (
    <ErrorBoundary onError={processError}>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </ErrorBoundary>
  );
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export default useAuth;
