/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useState, useMemo } from 'react';

interface NavigationProviderProps {
  children: JSX.Element;
}

export type NavType = 'top' | 'side';

export interface INavigationContext {
  navType: NavType;
  setNavType: (type: NavType) => void;
  debug: boolean;
  setDebug: (debug: boolean) => void;
  reset: () => void;
}

// createContext matches the shape that the consumers expect!
export const NavigationContext = createContext<INavigationContext>({
  navType: 'side',
  setNavType: () => undefined,
  debug: false,
  setDebug: () => undefined,
  reset: () => undefined,
});

export const NavigationProvider = ({
  children,
}: NavigationProviderProps): JSX.Element => {
  const defaultNavType = 'side';
  const [navType, setNavType] = useState<NavType>(defaultNavType);
  const [debug, setDebug] = useState<boolean>(false);

  const setNav = (type: NavType) => {
    if (type) setNavType(type);
  };

  const reset = () => {
    setDebug(false);
    setNavType(defaultNavType);
  };

  const value: INavigationContext = useMemo(
    () => ({
      navType,
      setNavType: setNav,
      debug,
      setDebug: (dbug: boolean) => setDebug(dbug === true),
      reset,
    }),
    [],
  );

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = (): INavigationContext => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};

export default NavigationContext;
