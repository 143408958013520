import { lazy } from 'react';
import { Form, Submission, Submissions } from './Forms';
import { Account, User, Users } from './Settings';
import { PasswordChange, PasswordReset, PasswordSend } from './Password';

const Login = lazy(() => import('./Login'));
const Logout = lazy(() => import('./Logout'));
const Home = lazy(() => import('./Home'));
const Debug = lazy(() => import('./Debug'));

export {
  Account,
  Debug,
  Form,
  Home,
  Login,
  Logout,
  PasswordChange,
  PasswordReset,
  PasswordSend,
  Submission,
  Submissions,
  User,
  Users,
};
