import { Navigate, Outlet } from 'react-router-dom';
import useAuth from './AuthContext';

const PrivateRoute = (): JSX.Element => {
  const { loggedIn, account: accountId } = useAuth();
  const account = accountId ? `account=${accountId}` : '';
  const redirect = `redirect=${window.location.pathname}`;
  const query = [account, redirect].filter((x) => x.trim() !== '').join('&');
  return loggedIn ? (
    <Outlet />
  ) : (
    <Navigate
      to={`/login?${query}`}
      state={{ referrer: window.location.pathname }}
    />
  );
};

export default PrivateRoute;
