import type { IData } from '@lhaley2011/react-library';
import { fetchGraphQL } from '@lhaley2011/react-library';

interface GraphResponse {
  saveUser: {
    user: {
      id: string;
      name: string;
      email: string;
      membership: Membership;
    };
  };
}

interface Response {
  user: {
    id: string;
    name: string;
    email: string;
    membership: Membership;
  };
}

interface Membership {
  account: string;
  role: string;
}

const query = `
  mutation saveUser($user: SaveUserInput!) {
    saveUser(input: $user) {
      user {
        id
        name
        email
        membership {
          account
          role
        }
      }
    }
  }
`;

export default async (user: Partial<IData>): Promise<Response | null> => {
  const variables = {
    user,
  };
  const { data, errors } = await fetchGraphQL<GraphResponse>(query, variables);
  if (Array.isArray(errors) && errors.length > 0) {
    const e = new Error(errors[0].message);
    const stack = errors[0]?.extensions?.exception?.stacktrace?.join('\n');
    e.stack = stack || e.stack;
    throw e;
  }
  if (data === null || data === undefined) throw Error('Data is undefined');
  if (data.saveUser === null) return null;
  if (data.saveUser.user === null) return null;
  const { id, email, name, membership } = data.saveUser?.user || {};
  return {
    user: {
      id,
      name,
      email,
      membership,
    },
  };
};
