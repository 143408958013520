import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import {
  Account,
  Debug,
  Form,
  Home,
  Login,
  Logout,
  PasswordChange,
  PasswordReset,
  PasswordSend,
  Submission,
  Submissions,
  User,
  Users,
} from './Pages';
import { MyPassword, MyUser } from './Pages/Settings/MyUser';

const AppRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path='/' element={<PrivateRoute />}>
        <Route path='/' element={<Home />} />
        <Route path='/debug' element={<Debug />} />
        <Route path='/debug/forms/:id' element={<Form />} />
        <Route path='/forms/:formId/submissions' element={<Submissions />} />
        <Route path='/forms/:formId/submissions/:id' element={<Submission />} />
        <Route path='/settings/account' element={<Account />} />
        <Route
          path='/settings/account/switch'
          element={<Login switchAccount />}
        />
        <Route path='/settings/user' element={<MyUser />} />
        <Route path='/settings/user/password' element={<MyPassword />} />
        <Route path='/settings/users' element={<Users />} />
        <Route path='/settings/users/:id' element={<User />} />
        <Route
          path='/settings/users/:id/password'
          element={<PasswordChange />}
        />
      </Route>

      <Route path='/login' element={<Login />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/password/reset' element={<PasswordSend />} />
      <Route path='/password/reset/:token' element={<PasswordReset />} />
      <Route path='*' element={<div>404 Not Found</div>} />
    </Routes>
  );
};

export default AppRoutes;
