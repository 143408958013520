import {
  fetchGraphQL,
  GraphqlResponse,
  setSessionId,
} from '@lhaley2011/react-library';
import {
  Account,
  Features,
  Form,
  Right,
  User,
} from '../components/AuthContext';
import loginFragment, {
  LoginFragmentResponse,
} from '../mutations/login.fragment';

interface GraphResponse {
  login: LoginFragmentResponse;
}

interface Response {
  authenticated: boolean;
  session: string;
  rights: Right[];
  features: Features;
  user: User;
  account: Account;
  forms: Form[];
  memberships: Account[];
}

const query = `
  query getLoggedInUser {
    login {
      ...loginResponse
    }
  }
  ${loginFragment('LoginQueryResponse')}
`;

export default async (): Promise<GraphqlResponse<Response>> => {
  const variables = {};
  const { data, errors } = await fetchGraphQL<GraphResponse>(query, variables);
  if (data === undefined || data === null) return { errors, data: null };
  if (data.login === null) return { errors, data: null };
  setSessionId(data?.login?.id);
  const user = { ...data?.login?.user };
  const featuresArr = data?.login?.features || [];
  const features = featuresArr.reduce(
    (acc: Record<string, string>, { name, value }) => {
      acc[name] = value;
      return acc;
    },
    {},
  );
  return {
    errors,
    data: {
      authenticated: data?.login?.authenticated,
      session: data?.login?.id,
      account: data?.login?.account,
      user,
      forms: data?.login?.forms || [],
      rights: data?.login?.rights || [],
      features,
      memberships: data?.login?.accounts,
    },
  };
};
