import useAuth from '../../../AuthContext';
import { PasswordChange } from '../../Password';

const Password = (): JSX.Element => {
  const { user: loggedInUser } = useAuth();
  const id = loggedInUser?.id;
  return (
    <PasswordChange
      id={id}
      invalidUserRedirect='/settings/user'
      successRedirect='/settings/user'
      title='Change Password'
    />
  );
};

export default Password;
