import { useState } from 'react';
import type {
  IData,
  IForm,
  IFieldValuesObject as ValuesObject,
} from '@lhaley2011/react-library';
import { Button, Form, Page, PageTitle } from '@lhaley2011/react-library';
import useAuth from '../../../AuthContext';
import saveUser from '../../../../mutations/saveUser';

const getForm = (hidden: boolean, changePasswordHref: string): IForm => ({
  type: 'Form',
  title: 'My Profile',
  quickViewFields: ['name', 'role'],
  components: [
    {
      name: 'name',
      label: 'Name',
      type: 'TextField',
      validate: { required: true },
      inputMode: 'text',
      autoComplete: 'name',
      autoCapitalize: 'words',
      spellCheck: 'false',
    },
    // { type: 'BreakField' },
    {
      name: 'email',
      label: 'Email',
      type: 'TextField',
      validate: { required: true },
      inputMode: 'email',
      autoComplete: 'username',
      autoCapitalize: 'off',
      autoCorrect: 'off',
      spellCheck: 'false',
    },
    { type: 'BreakField', style: 'height: 50px' },
    {
      label: 'Change Password',
      type: 'ButtonField',
      href: changePasswordHref,
      hidden,
    },
  ],
});

const makeArrayIfNot = <T,>(val: T | T[]): T[] | undefined => {
  if (val === null || val === undefined) return undefined;
  if (Array.isArray(val)) return val;
  return [val];
};

const Right = ({
  edit,
  setEdit,
}: {
  edit: boolean;
  setEdit: (val: boolean) => void;
}) => {
  return (
    <Button type='button' onClick={!edit ? () => setEdit(!edit) : undefined}>
      Edit
    </Button>
  );
};

const User = (): JSX.Element => {
  const [saving, setSaving] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const { reinit, user: loggedInUser } = useAuth();
  const form = getForm(edit, '/settings/user/password');
  const data: IData = {
    id: loggedInUser?.id,
    email: loggedInUser?.email,
    name: loggedInUser?.name,
  };

  const onSubmit = async (submitData: IData) => {
    const roleArr = makeArrayIfNot<ValuesObject>(
      submitData?.role as ValuesObject[],
    );
    const role: string | undefined = roleArr
      ? roleArr[0].value?.toString()
      : undefined;
    const saveData: IData = submitData
      ? { ...submitData, role: role || 'guest' }
      : {};
    delete saveData.account;

    setSaving(true);
    try {
      const res = await saveUser(saveData);
      if (res === undefined || res === null) {
        setSaving(false);
        throw new Error('Invalid Response');
      }

      reinit();
      setSaving(false);
      setEdit(false);
    } catch (err) {
      setSaving(false);
      throw err;
    }
  };

  const onCancel = () => {
    setEdit(false);
  };

  return (
    <Page>
      <>
        <PageTitle
          right={!edit ? <Right edit={edit} setEdit={setEdit} /> : undefined}
        >
          {form.title}
        </PageTitle>
        {saving && <div>Saving Data</div>}
        <Form
          defaultData={data}
          form={{ ...form }}
          readOnly={!edit}
          onCancel={edit ? onCancel : undefined}
          onSubmit={onSubmit}
        />
      </>
    </Page>
  );
};

export default User;
