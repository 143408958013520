import { BrowserRouter as Router } from 'react-router-dom';
import {
  ErrorBoundary,
  ToastProvider,
  ThemeProvider,
  styled,
} from '@lhaley2011/react-library';
import { Suspense } from 'react';
import Navigation from './Navigation';
import Routes from './Router';
import { AuthProvider } from './AuthContext';
import { NavigationProvider } from './NavigationContext';
import Loading from './Loading';

const AppWrapper = styled.div`
  text-align: center;
  height: 100vh;
  width: 100%;
`;

const PageWrapper = styled.div(
  ({ theme }) => `
    background-color: ${theme.background};
    height: 100%;
    overflow-y: hidden;
    display: inline-block;
  `,
);

const App = (): JSX.Element => {
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <Suspense fallback={<Loading />}>
          <NavigationProvider>
            <ToastProvider>
              <Router>
                <AppWrapper className='app-wrapper'>
                  <AuthProvider>
                    <Navigation>
                      <PageWrapper className='app-page-wrapper'>
                        <ErrorBoundary>
                          <Suspense fallback={<Loading />}>
                            <Routes />
                          </Suspense>
                        </ErrorBoundary>
                      </PageWrapper>
                    </Navigation>
                  </AuthProvider>
                </AppWrapper>
              </Router>
            </ToastProvider>
          </NavigationProvider>
        </Suspense>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
